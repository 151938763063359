<template>
  <div v-if="!$isMobile">
    <ZHeader></ZHeader>
    <div class="content">
      <div class="lecture_name">
        <div></div>
        <div>{{ lectureName }}</div>
      </div>
      <div class="form">
        <el-form ref="formRef" :model="form" label-width="120px" :rules="rulesPC">
          <el-row :gutter="24" style="margin-top: 30px">
            <el-col :span="12">
              <el-form-item label="姓名" prop="userName">
                <el-input v-model="form.userName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="电话" prop="phone">
                <el-input v-model="form.phone"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="form.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="院校" prop="schoolName">
                <el-input v-model="form.schoolName"></el-input>
              </el-form-item>
              <div></div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12" style="text-align: center">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="pcSaveAndPush">保存</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <z-footer></z-footer>
  </div>
  <div v-else class="h5_body">
    <div class="h5_title">
      <div class="point"></div>
      <span style="margin-left: 0.25rem"></span>
      {{ lectureName }}
    </div>
    <div class="h5_card">
      <van-form
        @submit="saveAndPush"
        input-align="left"
        label-width="100%"
        error-message-align="left"
        :show-error="false"
        :scroll-to-error="true"
        ref="formRef"
      >
        <van-cell-group inset>
          <van-field
            v-model="form.userName"
            label="姓名"
            name="userName"
            :required="true"
            placeholder="请输入姓名"
            :rules="rules.userName"
          />
          <van-field
            v-model="form.phone"
            label="电话"
            name="phone"
            :required="true"
            placeholder="请输入电话"
            :rules="rules.phone"
          />
          <van-field
            v-model="form.email"
            label="邮箱"
            name="email"
            :required="true"
            placeholder="请输入邮箱"
            :rules="rules.email"
          />
          <van-field
            v-model="form.schoolName"
            name="schoolName"
            label="学校名称"
            :required="true"
            placeholder="请输入学校名称"
            :rules="rules.schoolName"
          />
        </van-cell-group>
        <div class="h5_bottom_btn">
          <van-button @click="cancel">取 消</van-button>
          <van-button style="margin-left: 0.17rem; background: #5a8cf9; color: #ffffff" native-type="submit">
            保 存
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script lang="js">
import { computed, defineComponent, reactive, toRefs, getCurrentInstance, onMounted, ref, watch } from 'vue'
import { util } from '@/utils/util.js'
import { useRoute, useRouter } from 'vue-router'
import { ZMessage } from '@/utils/confirm'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      form: {
        userName: '',
        phone: '',
        email: '',
        schoolName: ''
      },
      lectureName: route.query.name,
      rulesPC: {
        schoolName: [{ required: true, message: '请输入学校名称', trigger: 'change' }],
        userName: [{ required: true, message: '请输入真实姓名', trigger: 'change' }],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'change' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
            trigger: 'change',
            message: '手机号码格式不正确'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'change' },
          {
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z.]+$/,
            trigger: 'change',
            message: '邮箱格式不正确'
          }
        ]
      },
      rules: {
        schoolName: [{ required: true, message: '请输入学校名称', trigger: ['onChange', 'onBlur'] }],
        userName: [{ required: true, message: '请输入真实姓名', trigger: ['onChange', 'onBlur'] }],
        phone: [
          { required: true, message: '请输入电话号码', trigger: ['onChange', 'onBlur'] },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
            trigger: 'onChange',
            message: '手机号码格式不正确',
            validateEmpty: false
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: ['onChange', 'onBlur'] },
          {
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z.]+$/,
            trigger: 'onChange',
            message: '邮箱格式不正确',
            validateEmpty: false
          }
        ]
      }
    })

    const formRef = ref(null)

    function pcSaveAndPush() {
      proxy.$refs['formRef'].validate().then(() => {
        saveAndPush()
      })
    }

    function saveAndPush() {
      let data = {
        lectureId: Number(route.query.id),
        type: 0,
        ...state.form
      }
      proxy.$http.campus.appointmentNew(data).then(() => {
        ZMessage({
          message: `预约成功！`,
          type: 'success'
        })
        setTimeout(() => {
          router.go(-1)
        }, 1000)
      })
    }

    function cancel() {
      router.go(-1)
    }
    return {
      ...toRefs(state),
      saveAndPush,
      cancel,
      formRef,
      pcSaveAndPush
    }
  }
})
</script>
<style lang="scss" scoped>
.search {
  width: 100%;
  height: 91px;
  background: #ffffff;
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  margin-top: 3px;
}
.content {
  width: 1200px;
  background: #ffffff;
  box-shadow: 0px 5px 11px 0px rgba(96, 100, 102, 0.1);
  border-radius: 16px;
  padding: 0 27px;
  margin: 20px auto;
  padding-bottom: 10px;
  overflow: hidden;
  min-height: 600px;
}
.lecture_name {
  width: 100%;
  padding-top: 32px;
  padding-bottom: 11px;
  border-bottom: 1px solid rgba(241, 243, 246, 1);
  position: relative;
  & > div:nth-child(1) {
    width: 5px;
    height: 26px;
    position: absolute;
    top: 37px;
    background: #6bafff;
    border-radius: 3px;
  }
  & > div:nth-child(2) {
    margin-left: 28px;
    font-size: 27px;
    font-weight: 600;
    color: #333333;
  }
}
.form {
  width: 100%;
}
.h5_title {
  margin-top: 0.33rem;
  font-size: 0.38rem;
  font-weight: 600;
  color: #1d2126;
  .point {
    width: 0.08rem;
    height: 0.29rem;
    background: #709af6;
    border-radius: 0.08rem;
    float: left;
    margin-top: 0.13rem;
    margin-left: 0.33rem;
  }
}
::v-deep .z-header__search {
  margin-bottom: 16px;
}
.h5_body {
  width: 100vw;
  overflow: hidden;
}
.h5_card {
  width: 7.17rem;
  margin: 0.21rem auto 0.27rem;
  margin-bottom: 1.4rem;
  box-sizing: border-box;
  padding: 0.42rem 0.33rem;
  border-radius: 0.3rem;
  background: #fff;
  ::v-deep .el-form-item--default {
    border-bottom: 0.02rem solid #e9edf1;
  }
}
.hearContent {
  border-bottom: none !important;
  ::v-deep .van-cell {
    border-radius: 0.25rem;
    border: 0.02rem solid #e9edf1;
  }
}
.h5_bottom_btn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 1.17rem;
  background: #ffffff;
  box-shadow: 0rem -0.08rem 0.21rem 0rem rgba(106, 122, 156, 0.1);
  display: flex;
  justify-content: center;
  & > button {
    width: 3.5rem;
    height: 0.83rem;
    border-radius: 0.42rem;
    border: 0.02rem solid #eef1f4;
    margin-top: 0.17rem;
    box-sizing: border-box;
    font-size: 0.33rem;
    color: #9e9e9e;
    line-height: 0.83rem;
    text-align: center;
  }
}
::v-deep .el-date-editor.el-input {
  width: 100% !important;
}
::v-deep .el-select {
  width: 100%;
}
::v-deep .van-popup--bottom {
  height: 45%;
  overflow: hidden;
  .van-picker {
    overflow: hidden;
  }
}
.van-cell {
  display: block;
  background: transparent;
  padding: 0;
  &::after {
    content: '';
    border-bottom: none;
  }
  &:last-child {
    :deep .van-field__body {
      border-bottom: none;
    }
  }
  :deep .van-cell__title {
    width: 100%;
    padding: 0.25rem 0;
  }
  :deep .van-field__body {
    border-bottom: 0.02rem solid #f2f3f4;
  }
  :deep .van-field__control {
    padding: 0 0 0.25rem 0;
  }
}
</style>
